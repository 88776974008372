.gender-btn {
    border: 1px solid #8b2845;
    color: #8b2845;
}
.gender-btn:Hover {
    background-color: #8b2845;
    color: #fff;
}
.submit-btn {
    background-color: #8b2845;
    padding: 0.8em 5.2em;
    color: #fff;
    margin-top: 1em;
}
.view-btn {
    font-size: 0.7em;
    padding: 1em;
    color: #8b2845;
    border: 1px solid #8b2845;
}

.slecdimond-btn {
    font-size: 0.7em;
    color: #fff;
    background: #8b2845;
}

.item-detls {
    color: #8f8f8f;
    font-size: 0.7em;
}
.suport-details {
    padding: 2em;
    border: 1px solid #8b2845;
    color: #8b2845;
    text-align: center;
}
.suport-details h4 {
    font-size: 1.5em;
}
.btn-group .btn-check:checked + .gender-btn {
    background-color: #8b2845;
    color: #fff;
  }