body {
    color: #777;
    font-size: 14px;
}

.btn-primary {
    color: #fff;
    background-color: #8b2846;
    border-color: #8b2846;
}

.btn.btn-primary {
    padding: 12px 20px;
    font-size: 13px;
    line-height: 18px;
    background-color: #8b2846;
    border-color: #8b2846;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    border-width: 0;
    border-style: solid;
    border-color: transparent;
    border-radius: 0;
    box-shadow: none;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: none;
    letter-spacing: .3px;
    font-weight: 600;
    cursor: pointer;
    transition: color .25s ease, background-color .25s ease, border-color .25s ease, box-shadow .25s ease, opacity .25s ease;
}

.btn.btn-primary:hover {
    background-color: #891739;
    border-color: #891739;
}

.btn-check:focus + .btn-primary, .btn-primary:focus,
.btn-primary:hover {
    color: #fff;
    background-color: #891739;
    border-color: #891739;
    box-shadow: none;
}

.sp-page {
    font-family: "Lora", Arial, Helvetica, Sans-serif;
    font-size: 18px;
}

.sp-page h2 {
    font-size: 40px;
}

.sp-page h3 {

}

.sp-page h4 {

}

.sp-page h2,
.sp-page h3,
.sp-page h4 {
    font-family: "Marcellus SC", Arial, Helvetica, sans-serif;
    font-weight: 400;
}

.sp-page .h2-primary {
    color: #8a2846;
    font-size: 30px;
    /*line-height: 2.8em;*/
}

.sp-page .h2-sec {
    color: #54595f;
    font-size: 32px;
    /*line-height: 2.8em;*/
}

.invert-100 {
    filter: invert(1);
}

.sp-button {
    color: #fff;
    background-color: #414141;
    /*box-shadow: 0 0 10px 0 #282727;*/
    padding: 10px 20px;
}

.sp-button:hover,
.sp-button:focus {
    color: #fff;
    background-color: #8a2846;
}

.sp-button.pri {
    color: #fff;
    background: #e91e63;
    box-shadow: none;
}

.sp-button.pri:hover,
.sp-button.pri:focus {
    background-color: #414141;
    color: #fff;
}

.owl-theme .owl-dots {
    margin-bottom: -50px;
}

.owl-carousel .owl-nav button.owl-prev {
    left: -10px;
}

.owl-carousel .owl-nav button.owl-next {
    right: -10px;
}

/*EngagementRingsPage*/
#ring-style .owl-carousel .owl-nav button.owl-next,
#ring-style .owl-carousel .owl-nav button.owl-prev {
    font-size: 30px;
    line-height: 25px;
    height: 47px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#ring-style .owl-carousel .owl-nav button.owl-next span,
#ring-style .owl-carousel .owl-nav button.owl-prev span {
    margin-top: -3px;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
    text-decoration: none;
}

.autohide-arrows .owl-nav {
    opacity: 0 !important;
    transition: all 0.2s ease-in-out;
}

.autohide-arrows:hover .owl-nav {
    opacity: 1 !important;
}

#er-page-header {
    background-position: center center;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    background-size: cover;
}

#er-page-header .er-breadcrumb {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

#er-page-header .er-breadcrumb svg {
    width: 20px;
    height: 20px;
}

#er-page-header .er-breadcrumb svg path {
    fill: #000;
}

#er-page-header-inner {
    padding: 150px 0;

}

#ring-style p {
    font-size: 14px;
}

.ring-item:hover p {
    color: #8a2846;
}

.ring-style {
    width: 100%;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 200px;
}

.metaltype-btn {
    font-size: 18px;
    color: #000;
    background-color: #fff;
    padding: 12px 24px;
    border-radius: 3px;
    display: inline-block;
}

.metaltype-btn:hover,
.metaltype-btn:focus {
    background-color: #ffebeb;
    color: #000;
}

@media (max-width: 992px) {
    #er-page-header {
        text-align: center;

        background-image: none !important;
        padding: 0;

    }
}

@media (max-width: 768px) {
    .sp-page h2 {
        font-size: 30px;
    }

    #er-page-header .er-breadcrumb {
        filter: invert(0);
    }

    #er-page-header-inner {
        padding: 50px 0;
    }
}

/*WeddingBandsPage*/

#wedding-header {
    background-color: #F0E4D4;
}

#wedding-header .container-content {
    max-width: 60%;
    margin: 0 auto;
}

#wedding-header .container-content h2 {
    font-size: 48px;
}

#wedding-header .container-content p {
    font-size: 20px;
    color: #000;
}

#wedding-header .container-fluid {
    max-width: 1900px;
}


#wedding-bands h2 {
    color: rgb(84 89 95);
    font-size: 32px;
    text-transform: capitalize;
    margin-bottom: 20px;
}

#wedding-bands p {
    color: rgb(84 89 95);
    max-width: 85%;
    margin: 0 auto 20px auto;
    font-size: 18px;
}

#wedding-bands .owl-carousel {
    margin-bottom: 50px;
}

#wedding-bands .owl-carousel .owl-nav button.owl-prev,
#wedding-bands .owl-carousel .owl-nav button.owl-next {
    background: none !important;
    color: #FBB1B1;
    font-size: 20px;
    top: 58%;
}

#wedding-bands .owl-carousel .owl-nav button.owl-prev span,
#wedding-bands .owl-carousel .owl-nav button.owl-next span {
    font-size: 35px;
}

#CoupleBands,
#EternityBands {
    width: 100%;
    max-height: 150px;
}

#CoupleBands .item,
#EternityBands .item {
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#EternityBands .item img {
    max-width: 150px;
    max-height: 150px;
    object-fit: contain;
    object-position: center;
    -o-object-fit: contain;
    -o-object-position: center;
    margin: 0 auto;
}

#forher,
#forhim {
    max-width: 1600px;
}

#forher .item,
#forhim .item {
    text-align: center;
}

#forher .forher-bg {
    background-color: transparent;
    background-image: linear-gradient(180deg, #FFA2A278 0%, #FFFFFF 100%);
}

#forhim .forhim-bg {
    background-color: transparent;
    background-image: linear-gradient(180deg, #559EE691 31%, #FFFFFF 100%);
}

.forher-image,
.forhim-image {
    background-position: top center;
    background-size: cover;
    width: 100%;
    height: 100%;
}

.forher-overlay,
.forhim-overlay {

    background-position: top left;
    opacity: 0.1;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover;
}

.forher-inner,
.forhim-inner {
    z-index: 2;
}


.forher-inner h2,
.forhim-inner h2 {
    color: #54595f;
    font-size: 35px;
}


.forher-inner p,
.forhim-inner p {
    color: #000;
}


.forher-inner .item h3,
.forhim-inner .item h3 {
    font-size: 24px;
}

.forher-inner .item p,
.forhim-inner .item p {
    color: #777777;
    font-size: 14px;

}

.forher-inner .item img,
.forhim-inner .item img {
    max-width: 150px;
    margin: 0 auto;
}

/*FineJeweleryPage*/
#fj-header {
    background-size: cover;
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s;
    padding: 20px 0 150px 0;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
}

#fj-header .sp-subheading {
    font-size: 24px;
    color: #2d2a2a;
}

#fj-header .sp-heading {
    font-size: 60px;
    color: #2d2a2a;
    margin-bottom: 30px;
}

#fj-header .sp-para {
    font-size: 14px;
    color: #777777;
    margin-bottom: 20px;

}

#fj-header .sp-button {
    background-color: #212121;
}

.fn-box {
    padding: 64px 32px 64px 32px;
    background: #9e9e9e;
    background-position: top left;
    background-size: cover;
    height: 100%;
}

.fn-box h2 {
    font-size: 65px;
}

.fn-box p {
    font-size: 15px;
}

.fn-box .sp-button {
    text-transform: uppercase;
    box-shadow: none;
    margin-top: 10px;
    display: inline-block;
    font-size: 16px;
}

.fn-box-earrings h2 {
    color: #06315e;
}

.fn-box-rings h2 {
    color: #a41a2f;
}

.fn-box-bracelets h2 {
    color: #bf6b0d;
    mix-blend-mode: screen;
}

.fn-box-necklaces h2 {
    color: #006950;
    mix-blend-mode: multiply;
}

.fn-box-earrings p {
    color: #fff;
}

.fn-box-rings p {
    color: #7a7a7a;
}

.fn-box-bracelets p {
    color: #fff;
}

.fn-box-necklaces p {
    color: #fff;
}

.fn-box-earrings .sp-button {
    background-color: #06315e;
    border-color: #06315e;
}

.fn-box-rings .sp-button {
    background-color: #a41a2f;
    border-color: #a41a2f;
}

.fn-box-bracelets .sp-button {
    background-color: #bf6b0d;
    border-color: #bf6b0d;
}

.fn-box-necklaces .sp-button {
    background-color: #006950;
    border-color: #006950;
}


#material-type .sp-heading {
    font-size: 40px;
}

#material-type .fn-box-spacer {
    height: 250px;
}

#material-type .fn-box {
    padding: 64px 64px 64px 64px;
    height: 100%;
}


#material-type .fn-box h2 {
    font-size: 24px;
    letter-spacing: .4px;
}

#material-type .fn-box .sp-button {
    font-size: 13px;
}

.fn-box-white h2 {
    color: #656565;
}

.fn-box-rose h2 {
    color: #a41a2f;
}

.fn-box-yellow h2 {
    color: #2d2a2a;
}

.fn-box-platinum h2 {
    color: #1b1b1b;
}

.fn-box-white .sp-button {
    background-color: #656565;
}

.fn-box-rose .sp-button {
    background-color: #a41a2f;
}

.fn-box-yellow .sp-button {
    background-color: #2d2a2a;
}

.fn-box-platinum .sp-button {
    background-color: #1b1b1b;
}

@media (max-width: 992px) {
    #fj-header {
        background-image: none !important;
        background-color: #f1f1f3;
    }

    .fj-header-img {
        background-color: #f1f1f3;
    }
}


@media (max-width: 768px) {
    #fj-header .sp-heading {
        font-size: 40px;
    }

    .fn-box h2 {
        font-size: 45px;
    }

    #fj-header .sp-subheading {
        font-size: 25px;
    }

    #material-type .fn-box {
        padding: 64px 16px 48px 16px;
    }

    #material-type,
    #jewelry-type {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
    }

    .fn-box-spacer {
        display: none;
    }
}


/*Setting Diamond Overlay*/
.rc-slider-rail,
.rc-slider .rc-slider-track,
.rc-slider .rc-slider-handle,
.rc-slider-step {
    z-index: 1;
}

.rc-slider .rc-slider-handle {
    background-color: #8b2846;
    border: solid 1px #8b2846;

}

#filter-settings .form-label {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    font-family: "Marcellus SC", Arial, Helvetica, sans-serif;
}

.funnelContainer .funnelTypeIconWrapper--Y0LBw,
.funnelPart--Omb4B .funnelPartContent--Tenfk {
    padding: 13px 20px 13px 15px;
    color: #000;
    box-shadow: none;
    border: 0;
}

.funnelPart--Omb4B.active--bcso0 .funnelPartContent--Tenfk {
    background: #dde5ed;
    border-left: 1px solid #e5e5e5;
    padding: 13px 20px 13px 15px;
    color: #000;
    box-shadow: none;
}

.stepHead--mnSYI .stepNumber--Vl0cC {
    font-size: 3.2em;
    text-align: center;
    line-height: 1.8rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
}

.stepHead--mnSYI .stepTitle--VTN7H .stepTitleFirst--WuFot {
    display: block;
    font-weight: 400;
    font-size: 15px;
    text-transform: uppercase;

}

.funnelContainer .funnelTypeIconWrapper--Y0LBw span,
.stepData--lAuzk .funnelTypeIconWrapper--ormUy span {
    margin-left: 20px;
    display: inline-block;
    vertical-align: middle;
    width: auto;
}

.stepHead--mnSYI .stepTitle--VTN7H .stepTitleSecond--I3t9t {
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-top: 5px;
    line-height: 15px;
    font-weight: 400;
}

.funnelPart--Omb4B.active--bcso0 .funnelPartContent--Tenfk::after {
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #dde5ed;
    box-shadow: -1px 0 0 #dde5ed;
}

.stepData--lAuzk .funnelTypeIconWrapper--ormUy {
    border: 0;
}

.filter-sec .filter-iconList {
    display: inline-block;
    align-items: start;
}

.filter-sec .filter-iconList .iconList-item {

    display: inline-block;
    text-align: center;
    margin: 0 0.25rem 0.5rem;

    cursor: pointer;
    width: auto;
}

.filter-sec .filter-iconList .iconList-item label {
    padding: 0;
    width: 50px;
    border: 0;
    border-bottom: 2px solid transparent;

    background: transparent;
    border-radius: 0;
    padding-bottom: 1rem;
}

.filter-sec .filter-iconList .iconList-item label .shape_item_label {
    display: block;
    word-break: break-word !important;
    max-width: fit-content;
    margin: auto !important;
    font-size: 11px;
}

.filter-sec .filter-iconList input[type=radio]:checked ~ label {
    box-shadow: none;
    outline: none;
    z-index: 2;
    border-bottom: 2px solid #8a2846;
}

.filter-sec .filter-iconList .iconList-item:hover label {
    box-shadow: none;
    outline: none;
    z-index: 2;
    border-bottom: 2px solid #8a2846;
}

.funnelContainer .list-img.list-diamond {
    width: 35px;
    height: auto;
}

.funnelContainer .list-img.list-settings {
    width: 68px;
    height: auto;
}

.funnelContainer .list-img.list-ring {
    width: 64px;
    height: auto;
}

.filter-sec .filter-iconList .iconList-item label .list-img {
    max-width: 50px;
}

.filter-sec .filter-iconList .iconList-item label .list-img-outter {
    max-width: 50px;
    margin: 0 auto 4px auto;
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rc-slider-mark {
    top: -35px;
    z-index: 0;
}

.rc-slider-mark-text {
    display: inline-block;
    text-align: center;
    color: #656565;
    font-size: 15px;
    height: 60px;
}

#panAdvanceInner {
    padding: 20px;
    border: 1px solid #fbfbfb;
    background: #f3f4f5;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

body .MuiToggleButtonGroup-grouped {
    padding: 5px 11px;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.filter-sec .filter-iconList.ringType {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    justify-content: start;
}


.filter-sec .filter-iconList.ringType .ring_item label {
    width: 100px;
    display: inline-block;
    text-align: center;
    margin: 0 0.25rem 0.5rem;
    border: 0;
    height: 90px;
    border-bottom: 2px solid transparent;
    padding-bottom: 1rem;
}
@media(max-width: 768px){
    .filter-sec .filter-iconList.ringType .ring_item label{
        width: 60px;
        height: 75px;
    }
}

.filter-sec .filter-iconList .ring_item:hover label {
    box-shadow: none;
    outline: none;
    z-index: 2;
    border-bottom: 2px solid #8a2846;
}

.filter-sec .filter-iconList.ringType .ring_item_label {
    word-break: break-word !important;
    max-width: fit-content;
    margin: auto !important;
    font-size: 12px;
}

.filter-sec .filter-iconList.ring-metal {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    flex-wrap: wrap;
}

.filter-sec .filter-iconList.ring-metal .iconList-item label span.ring-metal_label {
    display: none;
}

.filter-sec .filter-iconList.ring-metal .iconList-item label {
    width: 90px;
    text-align: center;
}

.filter-sec .filter-iconList.ring-metal .iconList-item label .list-img {
    max-width: 90px;
    max-height: 90px;
}

@media (max-width: 768px) {
    .funnelContainer {
        height: 50px;
        /*overflow: hidden;*/
        border-right: 1px solid #d6d6d6;
    }

    .funnelPart--Omb4B .funnelPartContent--Tenfk .funnelRightChevron--pcUVq,
    .stepHead--mnSYI .stepNumber--Vl0cC,
        /*.stepData--lAuzk,*/
    .funnelContainer .funnelTypeIconWrapper--Y0LBw, .funnelPart--Omb4B .funnelPartContent--Tenfk
    .stepHead--mnSYI .stepTitle--VTN7H .stepTitleFirst--WuFot {
        display: none;
    }

    .stepHead--mnSYI .stepTitle--VTN7H .stepTitleSecond--I3t9t {
        margin: 0;
    }

    .funnelContainer .funnelTypeIconWrapper--Y0LBw, .funnelPart--Omb4B .funnelPartContent--Tenfk {
        padding: 0;
    }

    .funnelPart--Omb4B.active--bcso0 .funnelPartContent--Tenfk::after,
    .funnelPart--Omb4B .funnelPartContent--Tenfk::after {
        border-width: 25px 0 25px 13px;
    }
}

@media (max-width: 560px) {
    .funnelPart--Omb4B.active--bcso0 .funnelPartContent--Tenfk::after,
    .funnelPart--Omb4B .funnelPartContent--Tenfk::after {
        border-width: 32px 0 30px 14px;
    }
}


.filter-sec {
    font-family: 'Lato', sans-serif;

}

.prodImg-wrap img {
    height: 250px;
    max-width: 100%;
    object-fit: contain;
    object-position: center;
    width: 100%;
    /*background: #b2b1b6;*/
}

.fltrPlod-list .nav-tabs {
    overflow: hidden;
    /*border: 1px solid #ccc;*/
    background-color: #f1f1f1;
    /*width: 100%;*/
}

.fltrPlod-list .nav-tabs .nav-link {
    border-radius: 0;
    background-color: #ddd;
    transition: 0.3s;
    color: #3E3E3E;
    font-family: 'Lato', sans-serif;
    line-height: 17px;
    text-shadow: none;
    letter-spacing: .3px;
    padding: 14px 16px;
    font-weight: 600;
}

@media (max-width: 768px) {
    .fltrPlod-list .nav-tabs .nav-link {

        padding: 8px 10px;
    }
}

.fltrPlod-list .nav-tabs .nav-link:hover {
    background-color: #ddd;
    transition: 0.3s;
    color: #3E3E3E;
    box-shadow: inset 0 0 200px rgb(0 0 0 / 10%);
}

.fltrPlod-list .nav-tabs .nav-item.show .nav-link,
.fltrPlod-list .nav-tabs .nav-link.active {
    background-color: #8a2846;
    color: #fff;
}

.fltrPlod-list .nav-tabs .nav-item.show .nav-link:hover,
.fltrPlod-list .nav-tabs .nav-link.active:hover {
    background-color: #8a2846;
    color: #fff;

    box-shadow: inset 0 0 200px rgb(0 0 0 / 10%);
}

.fltr-itemList .tab-pane .item-views .btn-view {
    border-radius: 0;
    outline: none;
    padding: 14px;
    border: 0;
}

.fltr-itemList .tab-pane .item-views .btn-view:hover {
    background-color: #ddd;
}

.fltr-itemList .tab-pane .item-views .btn-view[disabled] {
    color: #fff;
    animation: none;
    background: #8a2846;
    opacity: 1;
    border: 0;
    border-radius: 0;
    outline: none;
}


.card.prodItem-card {
    position: relative;
    z-index: 1;
}

.card.prodItem-card .card-body .prod-dtl {
    text-align: center;
}

.card.prodItem-card .card-body .prod-dtl .short-desc a {
    font-family: "Lora", Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: #333;
    font-size: 14px;

}

.card.prodItem-card .card-body .prod-dtl .price-block {
    display: block;
    margin-bottom: 0;
    font-size: 15px;
    line-height: 1.3;
    color: #8a2846;
    font-weight: 600;
}

.pagination .page-item .page-link {
    background-color: #fff;
    border-color: #fff;
    /* padding-right: 5px; */
    /* padding-left: 5px; */
    min-width: 22px;
    height: 34px;
    color: #2d2a2a;
    font-weight: 600;
    font-size: 14px;
    transition: all .2s ease;
}

.pagination .page-item .page-link:hover {
    background-color: #EEE;
    border-color: #EEE;

}

.pagination .page-item.active .page-link {
    background-color: #8a2846;
    border-color: #8a2846;
    color: #fff;
}

.pagination .page-item.prev-page a,
.pagination .page-item.next-page a {
    font-size: 0;
}

.pagination .page-item.prev-page a:before {
    font-family: "Font Awesome 6 Pro";
    font-size: 15px;
    content: "\f053";
}

.pagination .page-item.next-page a:before {
    font-family: "Font Awesome 6 Pro";
    font-size: 15px;
    content: "\f054";
}

.diamond-heading {
    font-family: 'Marcellus SC', sans-serif;
    font-weight: 400;
    font-size: 34px;
    color: #000;
}

.MuiTab-root {

}

.MuiTab-root.Mui-selected {
    color: #8a2846 !important;
}

.MuiTabs-indicator {
    background-color: #8a2846 !important;
}

.dim-loader {
    position: absolute;
    background: rgb(64 64 64 / 62%);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 5;
    border-radius: 20px;
    color: #fff;
}

.learn-page .nav-pills .nav-link {
    display: block;
    background-color: white;
    color: black;
    padding: 15px;
    width: 100%;
    border: 0;
    outline: 0;
    text-align: left;
    cursor: pointer;
    transition: .3s;
    font-size: 17px;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: none;
    letter-spacing: .3px;
    font-weight: 600;

    border-radius: 0;
}

.learn-page .nav-pills .nav-link.active, .learn-page .nav-pills .show > .nav-link {
    background-color: #8b2845;
    color: #fff;
    border-radius: 0;
}

.learn-page p {
    color: #777;
    margin-bottom: 20px;
}


.learn-page h1 {
    display: block;
    margin-bottom: 20px;
    color: #2d2a2a;
    font-weight: 400;
    line-height: 1.4;
    font-size: 28px;
}

.learn-page h3 {
    display: block;
    margin-bottom: 20px;
    color: #2d2a2a;
    font-weight: 400;
    line-height: 1.4;
    font-size: 22px;
}


.cart-header {
    background-color: #8a2846;
    padding-top: 60px;
    padding-bottom: 60px;
    text-align: center;

}

.cart-header ul {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 22px;
}

.cart-header ul li {
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 5px;
    line-height: 1.2;
}

.cart-header ul li span,
.cart-header ul li a {

    color: #fff;
}

.cart-header ul li.step-inactive > span {
    color: #fff;
    opacity: 0.7;
}

.cart-header ul li.step-inactive a {
    opacity: 0.7;
}

.cart-header ul li.step-active > span {
    color: #fff;

}

.cart-header ul li:hover a,
.cart-header ul li.step-active a {
    opacity: 1;
}

.cart-header ul li:after {
    margin: 0 15px;
    font-weight: 700;
    font-size: 85%;
    opacity: .7;
    content: "\f061";
    color: #fff;
    font-family: "Font Awesome 6 Pro";
}

.cart-header ul li:last-child:after {
    display: none;
}

.empty-cart {
    padding: 40px 0;
}

.empty-cart i {

    display: block;
    margin-bottom: 20px;
    color: rgba(135, 135, 135, .15);

    font-size: 180px;
    line-height: 180px;

}

.cart-empty-heading {
    font-family: "Marcellus SC", Arial, Helvetica, sans-serif;
    font-weight: 400;
    position: relative;
    margin-top: 5vw;
    margin-bottom: 15px;
    color: #2d2a2a;
    text-align: center;
    font-size: 48px;
    line-height: 1.2;
}

.cart-empty-text {
    font-family: "Lora", Arial, Helvetica, sans-serif;
    font-weight: 400;
    position: relative;
    margin-top: 5vw;
    margin-bottom: 15px;
    color: #777;
    text-align: center;
    font-size: 15px;
    line-height: 25px;
}

.cart-summary {
    padding: 25px;
    /*border: 3px solid #EFEFEF;*/
}

.coupon-pill {
    background: #8b2846;
    border-radius: 0;
    padding: 3px 20px;
    color: #fff;
}

.coupon-pill .btn-close {
    cursor: pointer;
}

.alert-primary {
    color: #fff;
    background-color: #8b2846;
    border-color: #8b2846;
}

.text-primary {
    color: #8b2846 !important;
}

.link-primary {
    color: #8b2846 !important;
}

.link-primary:hover,
.link-primary:active,
.link-primary:focus {
    color: #861d3d !important;
    text-decoration: underline;
}

.form-check-input:checked {
    background-color: #8b2846;
    border-color: #8b2846;
}

.cart-item-action {
    text-align: end;
}

.cart-item-action button,
.cart-item-action a {
    text-decoration: none;
    border: 0;
    background: none;
    font-weight: 400;
    color: #777;
    padding: 1px;
    font-size: 15px;
    font-family: "Lora", Arial, Helvetica, sans-serif;
}

.cart-item-action button:hover,
.cart-item-action a:hover {
    text-decoration: underline;
    color: #000;
}

.cart-item-image {
    position: relative;
    max-width: 250px;
    height: 250px;
}

.cart-item-image .mask-1 {
    height: 100%;
    object-fit: contain;
    width: 100%;
    max-width: 250px;
}

.cart-item-image .mask-2 {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: absolute;
    width: 65px;
    height: 52px;
    border-radius: 4px;
    border: 1px solid #fff;
    cursor: pointer;
    left: 7px;
    top: 9px;
    z-index: 4;
    box-sizing: border-box;
}


.yourcart-item .dtl-list {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 10px 0;
}

.yourcart-item .dtl-list li .cart-item-inner {
    display: flex;
}

.yourcart-item .card-icon {
    fill: rgb(138, 138, 138);
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin: 3px 10px 0px 0px;
}

.yourcart-item .cart-item-list {
    min-width: 300px;
    width: 67%;
}

.yourcart-item .cart-item-title a,
.yourcart-item .cart-item-title {
    font-size: 20px;
    color: #000;
    text-decoration: none;

}

.yourcart-item .cart-item-title a:hover {
    color: #8b2846;
}

.yourcart-item .dtl-list li {
    position: relative;
}

.yourcart-item .dtl-list li .cartitem-detail {
    font-size: 15px;
}

.yourcart-item .dtl-list li .change-item {
    text-decoration: none;
    font-weight: 400;
    color: #777;
    padding: 1px;
    font-size: 15px;
    font-family: "Lora", Arial, Helvetica, sans-serif;
    bottom: 0;
    background: none;
    border: 0;

}

.yourcart-item .dtl-list li .change-item:hover {
    text-decoration: underline;
    color: #000;
}

.login-box {
    font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
    padding: 0 15px 10px;
    border-bottom: 1px solid rgba(129, 129, 129, 0.2);

}

.login-box [type='submit'] {
    padding: 12px 20px;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: .3px;
    font-weight: 600;
}

.login-box .form-label {
    display: block;
    margin-bottom: 5px;
    color: #464646;
    vertical-align: middle;
    font-weight: 400;
    font-size: 14px;
}


.login-box .link-action {
    color: #464646;
    font-weight: 400;
    font-size: 14px;
}

.social-login-title {
    margin-top: 10px;
    margin-bottom: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;
    text-transform: uppercase;
    font-size: 14px;
    font-family: "Marcellus SC", Arial, Helvetica, sans-serif;
    font-weight: 400;
}

.social-login-title:before,
.social-login-title:after {
    content: "";
    flex: 1 0 0;
    border-bottom: 1px solid rgba(129, 129, 129, 0.2);

}

.social-login-title:before {
    margin-right: 20px
}

.social-login-title:after {
    margin-left: 20px
}

.register-box {
    padding-top: 20px;
    padding-right: 15px;
    padding-bottom: 20px;
    padding-left: 15px;
    border-bottom: 1px solid rgba(129, 129, 129, 0.2);
    text-align: center;
    font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.register-box:before {
    display: block;
    margin-bottom: 15px;
    color: rgba(135, 135, 135, .15);
    font-weight: 400;
    font-size: 54px;
    line-height: 54px;
    content: "\f007";
    font-family: "Font Awesome 6 Pro";
}


.register-box .create-account-text {
    margin-bottom: 15px;
    color: #2d2a2a;
    font-weight: 600;
    font-size: 14px;
}

.register-box .create-account-button {
    padding: 0;
    border-width: 2px;
    border-style: solid;
    border-top: none;
    border-right: none;
    border-left: none;
    letter-spacing: .3px;
    font-weight: 600;
    background-color: transparent !important;
    color: #333;
    border-color: #8a2846;
    border-radius: 0;
}

.register-box .create-account-button:hover {
    color: #333;
    opacity: .6;
}

.productdetail-inner {
    background: #f4f4f4;
}

.productdetail-inner .diamond-heading {
    font-weight: 600;
    font-family: "Lora", Arial, Helvetica, sans-serif;
    font-size: 34px;
    line-height: 1.2;
    margin-bottom: 0;
}

.productdetail-inner .diamond-subtitle {
    margin-top: 0;
    font-weight: 500;
    color: #949494;
    line-height: 100%;
}

.productdetail-inner .product_meta {
    display: block;
    line-height: 1.2;
    color: #aaa;
    font-weight: 400;
    font-size: 14px;
}

.productdetail-inner .pricePack {

    font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;;
    margin: 0 0 1em;
}

.productdetail-inner .pricePack .total {
    font-size: 22px;
    line-height: 1.2;
}

p.stock {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
    color: #333;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

p.stock:before {
    display: inline-block;
    margin-right: 5px;
    vertical-align: text-top;

}

p.stock.in-stock:before {
    content: "\f107";
    font-family: woodmart-font;

}

p.stock.out-stock:before {
    content: "\f106";
    font-family: woodmart-font;
}

.productdetail-inner .product-additional-galleries {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    padding: 30px 30px 15px;
}

.productdetail-inner .product-additional-galleries .wd-gallery-btn {
    margin-bottom: 10px;
    border: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    min-width: 50px;
    height: 50px;
    border-radius: 30px;
    background: rgba(255, 255, 255, .9);
    box-shadow: 0 0 5px rgb(0 0 0 / 8%);
    border: 1px solid #bdbdbd;
    color: rgba(0, 0, 0, .7);
    font-size: 13px;
    padding: 0;
    text-transform: none;
}

.wd-gallery-btn:before {
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 16px;
    line-height: 50px;
    content: "\f130";
    font-family: woodmart-font;
    font-weight: 400;
}

.product-360-button:before {
    font-size: 26px;
    content: "\f102";
    font-family: woodmart-font;
}
.product-video-button:before {
    font-size: 26px;
    content: "\f101";
    font-family: woodmart-font;
    left: 2px;
    position: relative;
}
.product-enlarge-button:before {
    content: "\f127";
    font-family: woodmart-font;
}

.wd-gallery-btn span {
    overflow: hidden;
    padding: 0;
    max-width: 0;
    white-space: nowrap;
    font-weight: 600;
    transition: padding .4s cubic-bezier(.175, .885, .32, 1.15), max-width .4s cubic-bezier(.175, .885, .32, 1.15);
}

.wd-gallery-btn:hover span {
    padding-right: 25px;
    max-width: 280px;
}

.mfp-iframe-scaler iframe,
.mfp-iframe {
    max-width: 500px;
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    margin: 0 auto;
    right: 0;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
    position: fixed;
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
    margin: 0;
    padding: 0;
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, .3);
    color: #FFF;
    text-align: center;
    opacity: 1;
    cursor: pointer;
    transition: all .25s ease;
    font-size: 24px;
    font-weight: normal;
}

.mfp-image-holder .mfp-close:hover,
.mfp-iframe-holder .mfp-close:hover {
    background-color: rgba(0, 0, 0, .5);
    box-shadow: none;
    color: #FFF;
}


.swiper-thumbs .swiper-slide {
    cursor: pointer;
}

.swiper.SwiperThumb {
    height: 100px;
}

.SwiperThumb .swiper-slide {
    position: relative;
    width: 100px;
    height: 100px;
}

.SwiperThumb .swiper-slide img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.RFRModal {

}

.RFRModal .modal-content {
    border-radius: 0;
    color: #2d2a2a;
    padding: 10px;
}

.RFRModal .modal-content h2 {
    font-family: "Lato", 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-size: 28px;
    text-align: center;
    font-weight: 700;
}

.RFRModal .modal-content h3 {
    font-family: "Lora", Sans-serif;
    font-size: 19px;
    margin-bottom: 20px;
    font-weight: bold;
}

.RFRModal .modal-content .para-modal {
    padding: 20px 0;
}

.RFRModal .modal-content a {
    color: #444;
    font-size: 14px;
    font-family: "Lora", Sans-serif;
}

.RFRModal .modal-content p {
    font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
    color: #444;
    font-size: 14px;
    margin-bottom: 0;

}

.RFRModal .close {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 35px;
    height: 35px;
    font-size: 0;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    line-height: 29px;
    fill: #fff;
    border-radius: 50px;
    background-color: #000;
    border: 3px solid #fff;
    outline: 0 !important;
    cursor: pointer;
}

.product-share {
    padding: 20px 0;
}

.product-share-label {
    margin-right: 5px;
    color: #1B1919;
    vertical-align: middle;
    font-weight: 600;
    font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 14px;
    display: flex;
    align-items: center;

}

.product-share button {
    margin-right: 10px;
}

.product-share i {
    font-size: 32px;
}

.fa-facebook {
    color: #4267b2;
}

.fa-whatsapp {
    color: #25d366;
}

.table-heading {
    font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #2d2a2a;
}

.table-body .dtlInfo {
    list-style: none;
    margin: 0;
    padding: 0;
}

.table-body .dtlInfo li {
    border-bottom: 1px solid #E6E6E6;
    text-transform: none;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
}

.table-body .dtlInfo li div:first-child {
    font-family: "Marcellus SC", Arial, Helvetica, sans-serif;
    color: #2d2a2a;


}

.table-body .dtlInfo li div:last-child {
    color: rgba(0, 0, 0, 0.87);
}

.table-body .dtlInfo li:last-child {
    border: 0;
}


.assurance-body h5 {
    font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #2d2a2a;
    font-weight: 700;

}

.MuiCard-root {
    border: 1px solid #eee !important;
    box-shadow: none !important;
    background-color: white;
}


.filter-sec .filter-iconList.jewellery-type {
    justify-content: center;
}

.filter-sec .filter-iconList.jewellery-type .iconList-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    border-radius: 6px;
    margin-bottom: 5px;
}

.filter-sec .filter-iconList.jewellery-type .iconList-item label {
    display: block;
    border: 1px solid #777;
    color: #464646;
    vertical-align: middle;
    font-weight: 400;
    font-size: 14px;
    padding: 16px;
    width: auto;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;
    font-family: "Lora", Arial, Helvetica, Sans-serif;

}

.select-placeholder-text {
    color: #000;
    font-size: 15px;
}

.filter-sec .filter-iconList.jewellery-type .iconList-item label .list-img {
    max-height: 48px;
    object-fit: contain;
    max-width: initial;
    object-position: center;
    margin-left: -5px;
}

.filter-sec .filter-iconList.jewellery-type .iconList-item label:hover,
.filter-sec .filter-iconList.jewellery-type input[type=radio]:checked ~ label {
    border: 1px solid #8a2846;
    box-shadow: inset 0 0 0 1px #8a2846;
}

.multiselect-check,
.multiselect-dropdown .dropdown-toggle {
    font-family: "Lora", Arial, Helvetica, Sans-serif;
    min-width: 200px;
    text-align: left;
    background-color: #ffffff;
    border-color: #3E3E3E;
    border-radius: 6px;
    border-style: solid;
    border-width: 1px;
}

.multiselect-control {
    border-color: #3E3E3E;
}

.multiselect-control-focus,
.multiselect-control:hover,
.multiselect-control:focus {
    border-color: #8a2846 !important;
    box-shadow: none !important;
}

.multiselect-check {
    border: 0;
}

.multiselect-dropdown .dropdown-toggle .arrow {

    color: #CCCCCC;
    padding: 8px;
    position: absolute;
    top: 0px;
    right: 0;
}

.multiselect-dropdown .dropdown-toggle .arrow:before {
    content: '';
    width: 1px;
    height: 20px;
    background-color: #CCCCCC;
    /* margin-bottom: 8px; */
    /* margin-top: 8px; */
    color: #CCCCCC;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}

.multiselect-dropdown .dropdown-toggle::after {
    display: none;
}

.home-card {

}

.home-card:hover {
    box-shadow: 0 0 5px #d1cbcb;

}

.home-card img,
.cate-btn {
    transition: all 0.2s ease-in-out;

}

.cate-btn {

    border: 0;
    background-color: transparent;
    color: #222;
}

.home-card .btn-text {
    position: absolute;
    padding: 0 0 30px 0;
    margin: 0;
    display: flex;
    align-items: end;
    justify-content: center;
    align-content: end;

}

.home-card .btn-text .cate-btn {
    margin: 0;
    font-size: 15px;
    font-family: 'Lato',sans-serif;
    background: #fff;
}

.home-card:hover img {
    transform: scale(1.1);
}

.home-card:hover .cate-btn {
    /*opacity: 0;*/

    background-color: transparent;
    border: 2px solid transparent;
    color: #fff;
    background-color: #8b2845;
    border: 1px solid #8b2845;
    color: #fff;
}

.cate-btn:hover {
    margin-top: 10.3em;
    font-size: 0.9em;
    background-color: transparent;
    /*border: 2px solid #fff;*/
    /*font-weight: 700;*/
    border-radius: 0;
    color: #fff;

}
 

.form-control {
    border-radius: 0;

}

.form-control:focus {
    border-color: #000;
    box-shadow: none !important;
}

.page-title {
    background-color: #8a2846;
    background-size: cover;
    background-position: center center;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 60px;
    position: relative;
}

.page-title .entry-title {
    font-size: 68px;
    color: #FFF;
    font-family: "Marcellus SC", Arial, Helvetica, sans-serif;
    font-weight: 400;
}

.breadcrumbs,
.breadcrumbs a,
.breadcrumbs span {
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: .3px;
    font-weight: 600;
    font-size: 11px;
    padding: 0 8px;
}

.compare-icon:before {
    content: "\f128";
    font-family: woodmart-font;
    display: block;

    font-weight: 400;
    vertical-align: middle;
    margin-top: 4px;
}

.compareok-icon:before {
    content: "\f107";
    font-family: woodmart-font;
    display: block;

    font-weight: 400;
    vertical-align: middle;
    margin-top: 4px;
}

.empty-compare:before {
    content: "\f128";
    font-family: woodmart-font;
    display: block;
    margin-bottom: 20px;
    color: rgba(135, 135, 135, .15);
    font-weight: 400;
    font-size: 180px;
    line-height: 180px;
}

.empty-compare:before {
    content: "\f128";
    font-family: woodmart-font;
    display: block;
    margin-bottom: 20px;
    color: rgba(135, 135, 135, .15);
    font-weight: 400;
    font-size: 180px;
    line-height: 180px;
}

p.stock.in-stock:before {
    content: "\f107";
    font-family: woodmart-font;
}

.empty-page {
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #2d2a2a;
    text-align: center;
    font-weight: 500;
    font-size: 48px;
    line-height: 1.2;
}

.empty-page-text {
    margin-bottom: 0;
    text-align: center;
    font-size: 110%;
}

.empty-page-text + .return-to-shop {
    margin-top: 25px;
    margin-bottom: 5vw;
}

.empty-page-text {
    margin-bottom: 0;
    text-align: center;
    font-size: 110%;
}

.return-to-shop {
    text-align: center;
}

.card.prodItem-card .card-body .prodImg-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    /*    background-image: url("../../../public/assets/img/no_image.png");
        background-size: 100% auto;
        background-position: center;*/
}

.card.prodItem-card .card-body .prodImg-wrap > a {
    display: block;
    width: 100%;
}

.card.prodItem-card .card-body .prodImg-wrap .btnAction {
    display: inline-flex;
    align-items: stretch;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-left: 7px;
    padding-right: 7px;
    max-width: auto;
    width: auto;
    background: #FFF;
    box-shadow: 1px 1px 1px rgb(0 0 0 / 10%);
    transition: all .3s ease;
    top: unset;
    bottom: -100px;
    visibility: hidden;
    text-align: center;
}

.card.prodItem-card .card-body:hover .prodImg-wrap {
    z-index: 2;
}


.card.prodItem-card .card-body .prodImg-wrap .product_hover_img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background: #fff;
    transition: all .3s ease-in-out;
}

.card.prodItem-card .card-body:hover .prodImg-wrap .product_hover_img {
    opacity: 1;
    -webkit-transform: scale(1.09);
    transform: scale(1.09);

}

.card.prodItem-card .card-body:hover .prodImg-wrap .btnAction {
    visibility: visible;
    opacity: 1;
    bottom: 0px;
    transition: all .3s ease;
}

@media (max-width: 768px) {
    .card.prodItem-card .card-body .prodImg-wrap .btnAction {
        visibility: visible;
        opacity: 1;
        bottom: 0px;
        transition: all .3s ease;
    }
}

.compare-product-tr,
.compare-product-list {
    display: inline-table;
    vertical-align: top;
}

.compare-page {
    padding: 18px;
}

.compare-page .compare-product-tr .compare-table {
    width: 322px;
    display: block;
}

.compare-product-list {
    width: calc(100% - 323px);
    display: inline-block;

}

.compare-product-list .compare-table {
    max-width: 1200px;
    position: relative;
}


.compare-page .compare-table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    /*border: 1px solid #ddd;*/
    font-size: 14px;
    display: flex;

}

.compare-product-col {
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.compare-page .compare-table img {
    object-fit: cover;
    object-position: center;
    -o-object-fit: cover;
    -o-object-position: center;
    width: auto;
    max-height: 200px;
}

.compare-page .compare-table .product-title {
    text-align: center;
    margin: 10px auto;
    font-size: 14px;
    color: #333;
    font-family: 'Marcellus SC', sans-serif;
}

.compare-page .compare-table .product-price {
    text-align: center;
    color: #8a2846;
    font-size: 14px;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: bold;

}

.compare-product-list .compare-product-col {
    min-width: 320px;
    display: inline-table;
}

.compare-page p.stock {
    margin: 0;
    line-height: 1.4;
}


.compare-table ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.compare-table ul li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    word-break: break-word;
}

.compare-product-tr {
    border-right: 1px solid rgba(129, 129, 129, .2);
}

.compare-product-tr .compare-table ul li {
    font-size: 16px;
    line-height: 21px;
    font-family: "Marcellus SC", Arial, Helvetica, sans-serif;
    font-weight: 400;
}

.compare-table .compare-product-col ul li {
    text-align: center;
}

.compare-table ul li:nth-child(even) {

}

.compare-table ul li:nth-child(odd) {
    background-color: rgba(0, 0, 0, .03);
}


.grid-list-btn {
    max-width: 102px;
    right: 13px;
    border: 0 !important;
    top: 0px;
}


.grid-product-detail .price-block {
    color: #8b2846;
}

.grid-product-detail {
    font-family: "Lora", Arial, Helvetica, sans-serif;
}


.quickView .media img {
    width: 100%;
    border-radius: 10px;
}

.quickView .overview {
    border: 1px solid #e9e9e9;
    padding-top: 20px;
    border-radius: 10px;
}

.quickView .media {
    position: relative;
    max-width: 350px;
    height: 380px;
    margin: auto;
    width: 100%;
}

.quickView .media .badges {
    position: absolute;
    top: 20px;
}

.quickView .media .badges .badge {
    background: #8a2846;
    margin-bottom: 5px;
    padding: 4px 10px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    font-size: 12px;
    color: #fff;
}

.quickView .info {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    max-width: 350px;
    padding: 20px 10px 0 10px;
    margin: auto;
    font-weight: 700;
    color: #8a2846;
}

.quickView .actions {
    padding: 20px 26px;
    border-top: 1px solid #f2f2f2;
    margin-top: 22px;
    text-align: center;
}

.quickView .gia {
    width: 80px;
}

.quickView .details .item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 6px 4px;
    font-size: 14px;
    margin-right: 30px;
}

.quickView .details .item .label,
.quickView .details .item .value {
    margin-bottom: 0;
}

.quickView .media iframe {
    width: 100%;
    height: 100%;
    border: 0;
}


.loader-container {
    position: absolute;
    top: 0;
    z-index: 99999999;
    background: rgba(255, 255, 255, 0.60);
    width: 100%;
    height: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.loader-container-full {
    position: fixed;
    z-index: 99999999;
}

.loader {
    border: 5px solid #dddddd;
    border-top: 5px solid #8a2846;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    box-shadow: 0 0 10px #837e7e;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.form-select.focus {
    border-color: #000;
}


.riskfreeretail {
    color: #4183c4 !important;
    margin: 5px 0;
    font-size: 14px;
}

table.cut-detail-table tr td {
    padding: 15px 12px;
    border: none;
    border-bottom: 1px solid #E6E6E6;
    text-align: left;
    font-weight: inherit;
}

table.cut-detail-table tr td, table.color-information-table tr th, table.color-information-table tr td {
    border: 1px solid #E6E6E6;
}

.cut-detail-table a {
    color: #3f3f3f;
    text-decoration: none;
    transition: all .25s ease;
}

.RiskFreeContent,
.risk-free-inner-content {
    font-family: "Lora", Arial, Helvetica, sans-serif;
}

.common-page-header {
    background-color: #8a2846;
    background-size: cover;
    background-position: center center;
    padding-top: 60px;
    padding-bottom: 60px;
    color: #fff;
}

.common-page-tab.nav-pills .nav-link {
    display: block;
    background-color: inherit;
    color: black;
    padding: 22px 16px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 17px;
    border-radius: 0;
    line-height: 17px;
    text-transform: uppercase;
    text-shadow: none;
    letter-spacing: .3px;
    font-weight: 600;
    cursor: pointer;
    font-family: "Lora", Arial, Helvetica, sans-serif;
}

.common-page-tab.nav-pills .nav-link:hover {
    box-shadow: inset 0 0 200px rgba(0, 0, 0, .1);
    color: #8a2846;
}

.common-page-tab.nav-pills .nav-link.active,
.common-page-tab.nav-pills .show > .nav-link {
    background-color: transparent;
    color: #8a2846;
}

h1, h2, h3, h4, h5, h6, legend {
    display: block;
    margin-bottom: 20px;
    color: #2d2a2a;
    font-weight: 500;
    line-height: 1.4;
    font-family: "Marcellus SC", Arial, Helvetica, sans-serif;

}

h2 {
    font-size: 24px;
}

a {
    color: #3f3f3f;
    text-decoration: none;
    transition: all .25s ease;
}

a:active, a:focus, a:hover {
    color: #8a2846;
}


.cs-table-padding td {
    padding: 15px 12px;
    border: none;
    border-bottom: 1px solid #E6E6E6;
    text-align: left;
    font-weight: inherit;
    border-bottom: 0;
}


.circle-ja-container .icon-container {
    padding: 0.5em;
    /* vertical-align: text-bottom; */
    text-align: center;
    width: 2.5em;
    height: 2.5em;
    background-color: #000;
    color: #fff;
    border: 1px solid #333;
    border-radius: 50%;
    font-size: 1.5em;
}


.footer-bg {
    background-color: #EAEAEA;
    font-family: "Lora", Arial, Helvetica, sans-serif;
}


footer .container {
    max-width: 1222px;
}

footer h4 {
    font-family: "Lora", Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #2d2a2a;
}


.about-footer p {
    margin-bottom: 6px;
}

.about-footer a,
footer a {
    color: #727272;
}

.about-footer a:hover,
footer a:hover {
    color: #333;
}

.zoom-social-icons-list {
    margin: 0;
    padding: 0;
}

.follow-us ul li a {
    font-size: 1.4em;
    border: 0;
    border-radius: 100%;
    padding: 0;
    display: flex;
    height: auto;
    width: auto;
    text-decoration: none;
    margin-top: 1em;
    color: unset;
}


.zoom-social-icons-list {
    list-style-type: none;
}

.zoom-social-icons-list--rounded .socicon, .zoom-social-icons-list--rounded .genericon, .zoom-social-icons-list--rounded .academicons, .zoom-social-icons-list--rounded .dashicons, .zoom-social-icons-list--rounded .fa {
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
    text-decoration: none;
}


.zoom-social-icons-list--no-labels .zoom-social_icons-list__item {
    display: inline-block;
    margin: 4px;
    border: none;
}

.main-footer .zoom-social_icons-list__item {
    margin-left: 0 !important;
}

.screen-reader-text {
    display: none;
}

.follow-us .zoom-social_icons-list__link {
    text-align: center;
    text-decoration: none !important;
    transition: all 0.2s ease;
    color: #fff;
    font-size: 18px;
    margin: 0;
}

.socicon-facebook {
    background-color: #3b5998;
}

.socicon-twitter {
    background-color: #1da1f2;
}

.socicon-instagram {
    background-color: #e4405f;
}

.socicon-tiktok {
    background-color: #69C9D0;
}

.socicon-linkedin {
    background-color: #0077B5;
}


.social-icon {
    padding: 8px;
    line-height: 18px;
}

.social-icon:hover {
    opacity: 0.8;
}

.social-icon:before {
    font-family: "Font Awesome 6 Brands";
}

.social-icon.socicon-facebook:before {
    content: "\f09a";
}

.social-icon.socicon-twitter:before {
    content: "\f099";
}

.social-icon.socicon-instagram:before {
    content: "\f16d";
}

.social-icon.socicon-tiktok:before {
    content: "\e07b";
}

.social-icon.socicon-linkedin:before {
    content: "\f0e1";
}


.footer-bottom {
    margin: 0;
}

.faq-text h3 {
    color: #2d2a2a;
    font-size: 24px;
}

.faq-item .accordion-header .title-faq {
    color: #777777;
    font-size: 14px;
    font-weight: 700;
    /*line-height: 1;*/
    margin: 0;
    padding: 15px;
    border: 0;
    /*border-bottom: 1px solid #d4d4d4;*/
    cursor: pointer;
    outline: none;
    font-family: "Lora", Arial, Helvetica, sans-serif;
    box-shadow: none !important;
}

.faq-item .accordion-header .title-faq:hover,
.faq-item .accordion-header .title-faq[aria-expanded=true] {
    color: #8a2846;
}

.faq-item .accordion-header .title-faq:after {
    content: "\f0da";
    font-family: "Font Awesome 6 Pro";
    background: none;
    -webkit-mask: none;
    mask: none;
    /* font-size: 31px; */
    /*color: #777777;*/
    font-weight: 900;
    position: absolute;
    left: 0;
    top: 14px;
}

.faq-item .accordion-header .accordion-button:not(.collapsed)::after {
    content: "\f0d8";
    left: 0;
    top: 14px;
    transform: none !important;
}

.faq-btn {
    background-color: #212121;
    color: #FFF;
    border-radius: 35px;
    padding: 12px 20px;
    font-size: 13px;
    font-family: "Lora", Arial, Helvetica, sans-serif;
    line-height: 18px;
    text-transform: uppercase;
    border: none;
    box-shadow: inset 0 -2px 0 rgba(0, 0, 0, .15);
    transition: color .25s ease, background-color .25s ease, border-color .25s ease, box-shadow 0s ease, opacity .25s ease;
}

.faq-btn:hover,
.faq-btn:focus {
    background-color: #212121;
    color: #FFF;
    border-radius: 35px;
    padding: 12px 20px;
    font-size: 13px;
    line-height: 18px;
    border: none;
    opacity: .8;
    box-shadow: inset 0 -2px 0 rgba(0, 0, 0, .15);
}


.instagram-feeds ._wPDyp {
    flex-basis: calc(100% / 4 - 10px);
}

@media (max-width: 768px) {
    .instagram-feeds ._wPDyp {
        flex-basis: calc(100% / 2 - 10px)
    }
}

/*Ring anim*/
.ring-container {
    position: relative;
    width: 1166px;
    height: 500px;
    transform-origin: top;
    text-align: center;
    transform: translate(-50%, 0) scale(0.5);
}

@media (max-width: 768px) {
    .ring-container {
        transform: translate(-55%, -150px) scale(0.6);
    }
}

@media (max-width: 480px) {
    .ring-container {
        transform: translate(-55%, -50px) scale(0.48);
    }
}

@media (max-width: 360px) {
    .ring-container {
        transform: translate(-55%, -50px) scale(0.4);
    }
}

.ring-container .ring-img {
    position: absolute;
    z-index: 1;
    right: -42%;
    top: 174px;
}

.ring-container .diamond_sprint {
    background-image: url('../../../public/assets/img/Diamond_Sprite.png');
    /*background-image: url('..//assets/img/Diamond_Sprite.png');*/
    width: 318px;
    height: 266px;
    right: -5%;
    position: absolute;
    z-index: 1;
    transition: top 0.1s ease-out;
}

.ring-container .prong_img {
    position: absolute;
    z-index: 2;
    top: 338px;
    right: -1.5%;
}

.about-page-head {
    font-family: "Lora", Sans-serif;
    font-size: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: #2d2a2a;
}

.about-page-content {
    font-family: Lora, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-weight: 400;
}

.cart_text_quantity {
    vertical-align: middle;
}

.login-cover {
    background: linear-gradient(
            rgba(0, 0, 0, .6),
            rgba(0, 0, 0, .1)),
    url("../../../public/assets/img/login-bg.jpg") 50% / cover;
}

.register-cover {
    background: linear-gradient(
            rgba(0, 0, 0, .6),
            rgba(0, 0, 0, .1)),
    url("../../../public/assets/img/register-bg.jpg") 50% / cover;

}

.cover-image {
    position: relative;
}

.loginpage-social-login {

}

.loginpage-social-login span {
    display: inline-block;
}

.loginpage-social-login span div button {
    width: auto !important;
    display: inline-block;
}

.loginpage-social-login span div button div[style="width: 10px;"] {
    display: none;
}

.loginpage-social-login span div button div {
    margin: 0 !important;
}

.loginside-sociallogin button {
    margin: 0 0 10px 0 !important;
    border-radius: 0 !important;
    padding: 10px 15px !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-family: "Lora", Arial, Helvetica, sans-serif;
    font-weight: 600 !important;
    height: unset !important;

}

.loginside-sociallogin button div[style="text-align: left; width: 100%;"] {
    text-align: center !important;
}

@media (max-width: 768px) {


    .diamond-list-table th:nth-child(1),
    .diamond-list-table th:nth-child(2),
    .diamond-list-table th:nth-child(3),
    .diamond-list-table th:nth-child(4),
    .diamond-list-table th:nth-child(5),
    .diamond-list-table th:nth-child(6),
    .diamond-list-table th:nth-child(7) {
        display: none;
    }


    .diamond-list-table tr td:nth-child(2),
    .diamond-list-table tr td:nth-child(3),
    .diamond-list-table tr td:nth-child(4),
    .diamond-list-table tr td:nth-child(5),
    .diamond-list-table tr td:nth-child(6) {
        display: none;
    }

    .ring-list-table th:nth-child(1),
    .ring-list-table th:nth-child(2),
    .ring-list-table th:nth-child(3),
    .ring-list-table th:nth-child(4),
    .ring-list-table th:nth-child(5) {
        display: none;
    }


    .ring-list-table tr td:nth-child(2),
    .ring-list-table tr td:nth-child(3),
    .ring-list-table tr td:nth-child(4),
    .ring-list-table tr td:nth-child(5) {
        display: none;
    }


}

.megamenu .container {
    max-width: 1920px;
}

.subLinks li {
    padding-bottom: 5px;
}

#siteNav .imageCol img {
    height: 250px;
}

@media (max-width: 1280px) {
    #siteNav > li > a {
        padding: 10px;
    }
}

@media (max-width: 1190px) {
    #siteNav > li > a {
        padding: 10px 5px;
        font-size: 13px;
    }
}

.navOn {
    display: none;
}

.logo {
    vertical-align: middle;
}

@media (max-width: 1024px) {
    .navOn {
        display: inline-block;
    }

    .logo {
        margin: 0 0 0 10px;
    }
}


@media (max-width: 1200px) {
    .stepHead--mnSYI.stepOne--RZqJG,
    .stepHead--mnSYI {
        margin-left: 10px;
    }

    .stepHead--mnSYI .stepNumber--Vl0cC {
        margin-right: 10px;
    }

    .stepHead--mnSYI .stepTitle--VTN7H .stepTitleFirst--WuFot {
        font-size: 14px;
    }

    .stepHead--mnSYI .stepTitle--VTN7H .stepTitleSecond--I3t9t {
        font-size: 18px;
    }

}

.text-ellipois {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ring_item_icon {
    max-width: 75px;
    margin: 0 auto 4px auto;
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ring_item_icon img {
    max-width: 100%;
    height: auto;
}

.swiper-button-next, .swiper-button-prev {
    color: rgb(195 195 195 / 71%);
}

.mySwiperVerThumb {
    height: 100%;
}

.mySwiperVerThumb .swiper-slide {
    width: 100px;
    height: 100px !important;
}

.mySwiperVerThumb .swiper-slide img {
    width: 100px;
    height: 100px;

}

summary::before {
    content: "\f078";
    font-family: "Font Awesome 6 Pro";
}

.form-check {
    cursor: pointer;
}

.mini_cart.active {
    max-width: 320px;
}

.custom-offer-sec,
.create-ring-sec {
    overflow: hidden;
}

.off-canvas-item #mobNav-menu .accordion-item .accordion-body {
    background: #fff;
}

.off-canvas-item #mobNav-menu .accordion-item .accordion-body .menu-title {
    font-size: 17px;
    margin-bottom: 12px;
    display: block;
}

.off-canvas-item #mobNav-menu .accordion-item .accordion-body ul {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0;
}

.off-canvas-item #mobNav-menu .accordion-item .accordion-button a {
    text-transform: uppercase;
}

.orderTable {
    min-width: 768px;
}

@media (max-width: 1192px) {
    .container {
        max-width: 100%;
    }

    .ring-create-btn a {
        font-size: 14px;
    }

    .engagement-ring-create h1 {
        /*text-align: right;*/
        /*font-size: 3em;*/
    }
}

@media (max-width: 1024px) {

    .custom-offer-title {
        margin-top: 2.4em;
    }

    .engagement-ring-create h1 {
        font-size: 3em;
    }

    .engagement-ring-create .ring-create-btn {
        /*flex-direction: column;*/
        justify-content: center !important;
    }

    .engagement-ring-create h1,
    .engagement-ring-create h6,
    .engagement-ring-create p {
        text-align: center;
    }

    #siteNav {
        display: none;
    }

}

@media (max-width: 991px) {
    .ring-container {
        transform: translate(-42%, 0) scale(0.5);
    }

    .custom-offer-benner {
        margin-top: 2rem;
    }
 
    .owl-carousel.d-carousel-cener .active.center .dc-inner .dc-containt h2 {
        font-size: 20px;
        margin-top: 12px;
    }

    .crafted-design-img {
        height: 400px;
    }

    .crafted-design-bg {
        background-position: right;
    }
}

@media (max-width: 768px) {
    .ring-container {
        transform: translate(-46%, 0) scale(0.5);
    }

    .hero-text h1 {
        font-size: 48px;
    }

    .prod-dtlCont {
        margin-top: 20px;
    }

    .mySwiperVerThumb.swiper-vertical > .swiper-wrapper {
        flex-direction: row;
    }
}

@media (max-width: 650px) {
    .ring-container {
        transform: translate(-50%, 0) scale(0.5)
    }

    .slider-btn a {
        margin-bottom: 10px;
    }

    .engagement-ring-create h1 {
        font-size: 2em;
    }

    .engagement-ring-create .ring-create-btn {
        flex-direction: column;
    }

    .custom-offer-title {
        margin-top: 2em;
        font-size: 20px;
    }

    .crafted-design-text {
        padding: 1em;
        font-size: unset;
        transform: none;
        transform-origin: unset;
        margin-left: 0;
        border: 15px solid #000;
    }

    .home-card-outter {
        width: 50%;
    }

    .crafted-design-bg {
        padding: 30px;
    }

    .hero-text h1 {
        font-size: 30px;
    }
}

@media (max-width: 480px) {
    .ring-container {
        transform: translate(-58%, 0) scale(0.5);
    }

    .home-card-outter {
        width: 100%;
    }

}

@media (max-width: 412px) {
    .custom-offer-title {
        margin-top: 10px;
    }

    .header_middel .d-flex {
        flex-direction: column;
    }

    .header_middel .siteLogo {
        /*max-width: 100%;*/
        /*margin-bottom: 10px;*/
    }

    #userPan {
        padding: 5px;
    }

    .header_middel .d-flex {
        flex-direction: row;
    }
}


#dropdown-currency {
    border: 0 !important;
    background: #fff !important;
}

.dropdown-currency-menu {
    border-radius: 0;
}


@media (max-width: 480px) {
    #dropdown-currency span {
        display: none;
    }
}


.insta-card {
    border: 0;
}

.insta-card img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
}

@media (max-width: 412px) {
    .insta-card img {
        height: 150px;
    }
}

@media (max-width: 360px) {
    .header_middel .d-flex {
        flex-direction: column;
    }
    .navOn .btn {
             padding:0.75rem 0.275rem;
    }
}


.error404 {
    padding-top: 40px;
    padding-bottom: 40px;
}
.error404 .page-header:before {
    content: "404";
    position: absolute;
    top: -20px;
    left: 50%;
    color: #F4F4F4;
    font-weight: 700;
    font-size: 400px;
    line-height: 320px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.error404 .page-title {
    margin-top: 0;
    margin-bottom: 0;
    padding: 115px 0;
    border-bottom: none;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 900;
    font-size: 82px;
    line-height: 82px;
    background: transparent;
    color: #8a2846;
}
.error404 .page-content {
    text-align: center;
}
.error404 .page-content h2 {
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 28px;
    line-height: 1.2;
}
.error404 .page-content p {
    font-size: 110%;
}
@media (max-width: 1024px) {
    .error404 .page-content h2 {
        font-size: 24px;
    }
    .error404 .page-content p {
        font-size: 14px;
    }
}
@media (max-width: 768px) {
    .error404 .page-header:before {
        top: -10px;
        font-size: 150px;
        line-height: 120px;
    }
    .error404 .page-title {
        padding: 15px 0;
        font-size: 32px;
    }
    .error404 .page-content h2 {
        font-size: 18px;
    }
}

.captcha{
    /*background: #8b2846;*/
    /*border: 1px solid #8b2846;*/
    /*padding: 20px;*/
    max-width: 250px;
    text-align: left;
    border-radius: 20px;
}


.fj-single-page.filter-sec .filter-iconList.ring-metal .iconList-item label {
    width: 50px;
    padding-bottom: 5px;
}
.fj-single-page.filter-sec .filter-iconList.ring-metal .iconList-item label .list-img{
    max-width: 50px;
    max-height: 50px;
}

 


.fj-single-page.filter-sec .filter-iconList.fw-diamond .iconList-item{
    width: 100%;
    max-width: 100%;
    border: 1px solid rgba(0,0,0,0);
    margin-bottom: 10px;
    position: relative;
    background-color: #f8f8f8;
}


.fj-single-page.filter-sec .filter-iconList.fw-diamond .iconList-item label{
    width: 100%;
    text-align: left;
    max-width: 100%;
    border: 1px solid transparent;
    padding: 0px;
}

.fj-single-page.filter-sec .filter-iconList.fw-diamond .iconList-item label .diamond_p_box{
    padding: 10px;
    display: flex;
    align-items: center;
    height: 100%;
}
.fj-single-page.filter-sec .filter-iconList.fw-diamond .iconList-item label .diamond_p_box .diamond_p_icon{
    margin-top: -15px;
}
.fj-single-page.filter-sec .filter-iconList.fw-diamond .iconList-item label .diamond_p_box .diamond_p_text{
    font-size: 14px;
    color: #1b1b1b;
    padding-left: 8px;
    width: 100%;
}
.fj-single-page.filter-sec .filter-iconList.fw-diamond .iconList-item label .diamond_p_box .diamond_p_text .diamond_p_type{
    font-weight: normal;
    font-size: 15px;
}
.fj-single-page.filter-sec .filter-iconList.fw-diamond .iconList-item label .diamond_p_box .diamond_p_price{
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    min-width: 150px;
}


.fj-single-page.filter-sec .filter-iconList.fw-diamond.fw-metal .iconList-item{
    display: inline-block;
    width: 55px;
}
.fj-single-page.filter-sec .filter-iconList.fw-diamond.fw-carat .iconList-item{
    display: inline-block;
    width: 45px;
    height: 45px;

}

.fj-single-page.filter-sec .filter-iconList.fw-diamond.fw-metal .iconList-item label{
    text-align: center;
    padding: 0;
}
.fj-single-page.filter-sec .filter-iconList.fw-diamond.fw-carat .iconList-item label{
            text-align: center;

    padding: 10px;
}


.fj-single-page.filter-sec .infos .info-dtls .MuiBox-root .dtlInfo{

}
.fj-single-page.filter-sec .infos .info-dtls .MuiBox-root .dtlInfo li{
    background-color: #f4f4f4;
    font-size: 14px;
    height: 43px;
    display: flex;
    align-items: center;
    padding-left: 32px;
    color:#1b1b1b;
}
.fj-single-page.filter-sec .infos .info-dtls .dtlInfo li:nth-child(odd){
    background-color:#fbfbfb ;
}
@media(max-width: 560px){
    .fj-single-page.filter-sec .filter-iconList.fw-diamond .iconList-item label .diamond_p_box{
        display: block;
    }
    .fj-single-page.filter-sec .filter-iconList.fw-diamond .iconList-item label .diamond_p_box .diamond_p_icon {
        display: none;
    }
    .fj-single-page.filter-sec .filter-iconList.fw-diamond .iconList-item label .diamond_p_box .diamond_p_text{
        padding-left: 0;
    }
    .fj-single-page.filter-sec .filter-iconList.fw-diamond .iconList-item label .diamond_p_box .diamond_p_price{
        display: block;
    }
}
.filter-sec .filter-iconList.fw-diamond input[type=radio]:checked ~ label{
    background-color: #fff;
    border: 1px solid #1b1b1b;
}

.filter-sec .filter-iconList.fw-diamond input[type=radio] ~ label:hover{
    background-color: #fff;
    border: 1px solid #1b1b1b;
}
.fj-single-page.filter-sec {
    overflow: unset;
}
.fj-single-page .fw-sticky{
    top:100px;
}
.sticky-header.sticky{
    z-index: 999;
}


.video-modal .modal-body{
    padding: 0;
    margin-bottom: -6px;
}
.video-modal .modal-body video,
.video-modal .modal-body iframe{
    width: 100%;
}