
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    background-size: cover;
    background-position: center;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper {
    width: 100%;
    /*height: 300px;*/
    margin-left: auto;
    margin-right: auto;
  }
  
  .mySwiper2 {
    /* height: 50%; */
    width: 100%;
    /*height: 34em;*/
  }
  
  .mySwiperBottom{
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }
  
  .mySwiperBottom .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  
  .mySwiperBottom .swiper-slide-thumb-active {
    opacity: 1;
  }



  .mySwiperLeft {
    /* height: 50%; */
    box-sizing: border-box;
    padding: 10px 0;
  }
  
  .mySwiperLeft .swiper-slide {
    width: 100%;
    height: 100%;
    opacity: 0.4;
  }
  
  .mySwiperLeft .swiper-slide-thumb-active {
    opacity: 1;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }



.prod-dtlCont .choosenList-dtl {
    list-style: none;
    /* padding: 0; */
    margin-bottom: 5em;
}

.offer-banner .offer-text {
  z-index: 1;
}
.offer-banner .offer-text h4 {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 11px;
  text-transform: uppercase;
}
.offer-banner .offer-text h1 {
  font-weight: 700;
  font-size: 53px;
  text-transform: uppercase;
}
.offer-banner .offer-text h1 {
  font-weight: 700;
  font-size: 53px;
}
.offer-banner .offer-img {
  position: relative;
  min-height: 18rem;
}
.offer-banner .offer-img img {
  margin-top: -2em;
  margin-top: -5em;
  position: absolute;
  right: 0;
}

.offer-banner .offer-points ul {
  list-style: none;
  padding: 1em 0 0em 0em;
}

.offer-banner .offer-points ul li {
  margin-bottom: 15px;
  color: #656565;
  font-size: 17px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 36.48px;

}

.prod-dtlCont .choosenList-dtl .choosenItem {
    position: relative;
}

.prod-dtlCont .choosenList-dtl .choosenItem .item-title {
  color: #1c1c1c;
  margin-bottom: 0.4em;
  display: block;
}

.prod-dtlCont .choosenList-dtl .choosenItem .card-icon {
    position: absolute;
    height: 1em;
    width: 1em;
    left: -2em;
    top: 0.4em;
}

.prod-dtlCont .choosenList-dtl .choosenItem:not(:first-child) .card-icon {
  margin-top: 0.5em;
}

.prod-dtlCont .choosenList-dtl .choosenItem:not(:last-child) {
    padding-bottom: .5em;
    border-bottom: 1px solid #dbdbdb;
}

.prod-dtlCont .choosenList-dtl .choosenItem:not(:first-child) {
    padding-top: .5em;
}

.prod-dtlCont .choosenList-dtl .choosenItem .main-cont .itemDtl-customize > span {
  margin-right: 1em;
}

.prod-dtlCont .choosenList-dtl .choosenItem .main-cont .itemDtl-customize span.sku {
  color: #8f8f8f;
}

.prod-dtlCont .choosenList-dtl .choosenItem .main-cont .itemDtl-customize span.buy-price {
  color: #8b2845;
  font-weight: 500;
}
.prod-dtlCont .choosenList-dtl .choosenItem span.buy-price {
  color: #8b2845;
  font-weight: 500;
}

.prod-dtlCont .choosenList-dtl .choosenItem .main-cont .itemDtl-customize span.price-lineThrough {
  color: #505050;
  margin-right: .5em;
  text-decoration: line-through;
}
.rating label input[type="radio"] {
  display: none;
}

.pricePack {
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;
  /*align-items: end;*/
}

.pricePack .total {
  margin-right: 0.6em;
  font-size: calc(100% + 1em);
  line-height: 1.1;
}

.pricePack .total .old-price {
  text-decoration: line-through;
  margin-right: 0.5em;
  font-size: 0.7em;
}

.pricePack .total .now-price {
  color: #8b2845;
  font-weight: 700;
}




.infos .info-dtls .MuiBox-root {
  padding: 24px 0;
}
.infos .info-dtls .MuiBox-root .dtlInfo {
  list-style: none;
  padding: 0;
}

.infos .info-dtls .MuiBox-root .dtlInfo li {
  display: flex;
  border-radius: 5px;
}

.infos .info-dtls .MuiBox-root .dtlInfo li .cell {
  flex: 0 0 50%;
  padding: 0.3em .5em;
  font-size: calc(100% - .1em);
}

.infos .info-dtls .MuiBox-root .dtlInfo li:nth-child(odd) {
  background-color: #f3f3f3;
}

.offer-banner {
  /* height: 367px; */
  width: 100%;
  background-color: #f3f6fb;
  box-sizing: border-box;
  padding: 38px 0 60px 60px;
  position: relative;
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
}


@media only screen and (max-width: 767px) {
  .offer-img {
    order: 1;
}

.offer-text {
    order: 2;
}
  
}
@media only screen and (max-width: 515px) {
  .offer-points ul li {
    font-size: 14px;
}
.offer-text h1 {
  font-size: 39px;
}
.offer-text h4 {
  font-size: 20px;
}
.offer-points {
  display: block !important;
}

.offer-points ul {
  margin: 0 !important;
}
  
}
@media only screen and (max-width: 425px) {
  .offer-banner {
    padding: 38px 0 52px 17px;
}
.offer-img img {
  margin-left: 0;
}
.offer-points ul li {
  font-size: 13px;
}
.offer-points ul li {
  line-height: 24.48px;
}
  
}

