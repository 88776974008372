
.log-image1 .cover-image {
    min-height: 100%;
    width: 100%;
    position: absolute;
    background-color: #030303;
    top: 0;
    left: 0
}
.log-image1 .cont-main {
    min-height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 4em;
}